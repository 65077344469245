import { Title } from '@mantine/core';
import React from 'react';
import MainAppShell from '../components/Layout/MainAppShell';

const NotFound = () => (
  <MainAppShell withAuth={false}>
    <Title>404</Title>
  </MainAppShell>
);

export default NotFound;
